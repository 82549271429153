import * as d3 from 'd3';
import {
  WiSunrise, WiSunset, WiMoonrise, WiMoonset,
  WiMoonNew, WiMoonFull, WiMoonFirstQuarter, WiMoonThirdQuarter,
  WiMoonWaxingCrescent4, WiMoonWaningCrescent4, WiMoonWaxingGibbous4, WiMoonWaningGibbous4,
  WiDaySunny, WiDayCloudy, WiRain, WiDayRain, WiRainMix, WiDaySnow,
  WiThunderstorm, WiDayThunderstorm,
  WiCloudy,
  WiAlien
} from 'weather-icons-react';

export const ForecastDay = ({ data }) => {
  if (!data) {
    return null;
  }

  /*{
    validTimeLocal: data.validTimeLocal[i],
    dayOfWeek: data.dayOfWeek[i],
    narrative: data.narrative[i],
    min: data.temperatureMin[i],
    max: data.temperatureMax[i],
    sunriseTimeLocal: data.sunriseTimeLocal[i],
    sunsetTimeLocal: data.sunsetTimeLocal[i],
    moonPhase: data.moonPhase[i],
    moonPhaseCode: data.moonPhaseCode[i],
    moonPhaseDay: data.moonPhaseDay[i],
    moonriseTimeLocal: data.moonriseTimeLocal[i],
    moonsetTimeLocal: data.moonsetTimeLocal[i]
  }*/

  var timeFormatDate = d3.timeFormat("%d");
  var timeFormatTime = d3.timeFormat("%Hh%M");

  const iconColor = "#518bff";
  const bigIconSize = 50;

  // moon phase
  const moonPhases = {
    "N": { label: "Nouvelle lune", icon: <WiMoonNew size={bigIconSize} color={iconColor} /> },
    "F": { label: "Pleine lune", icon: <WiMoonFull size={bigIconSize} color={iconColor} /> },
    "FQ": { label: "Premier quartier", icon: <WiMoonFirstQuarter size={bigIconSize} color={iconColor} /> },
    "LQ": { label: "Dernier quartier", icon: <WiMoonThirdQuarter size={bigIconSize} color={iconColor} /> },
    "WXC": { label: "Premier croissant", icon: <WiMoonWaxingCrescent4 size={bigIconSize} color={iconColor} /> },
    "WNC": { label: "Dernier croissant", icon: <WiMoonWaningCrescent4 size={bigIconSize} color={iconColor} /> },
    "WNG": { label: "Lune gibbeuse croissante", icon: <WiMoonWaxingGibbous4 size={bigIconSize} color={iconColor} /> },
    "WXG": { label: "Lune gibbeuse décroissante", icon: <WiMoonWaningGibbous4 size={bigIconSize} color={iconColor} /> }
  };

  const moonPhase = moonPhases[data.moonPhaseCode];

  const weatherNarratives = {
    "Ciel plutôt dégagé": { label: "Ciel plutôt dégagé", icon: <WiDaySunny size={bigIconSize} color={iconColor} /> },
    "Nuageux": { label: "Nuageux", icon: <WiCloudy size={bigIconSize} color={iconColor} /> },
    "Très nuageux": { label: "Très nuageux", icon: <WiCloudy size={bigIconSize} color={iconColor} /> },
    "Partiellement nuageux": { label: "Partiellement nuageux", icon: <WiDayCloudy size={bigIconSize} color={iconColor} /> },
    "Averses": { label: "Averses", icon: <WiRain size={bigIconSize} color={iconColor} /> },
    "Averses orageuses": { label: "Averses orageuses", icon: <WiRain size={bigIconSize} color={iconColor} /> },
    "Averses dans la matinée": { label: "Averses dans la matinée", icon: <WiDayRain size={bigIconSize} color={iconColor} /> },
    "Averses au cours de l'après-midi": { label: "Averses au cours de l'après-midi", icon: <WiDayRain size={bigIconSize} color={iconColor} /> },
    "Averses de pluie et de neige mélangées": { label: "Averses de pluie et de neige mélangées", icon: <WiRainMix size={bigIconSize} color={iconColor} /> },
    "Pluie": { label: "Pluie", icon: <WiRain size={bigIconSize} color={iconColor} /> },
    "Épisode pluvieux en fin de journée": { label: "Épisode pluvieux en fin de journée", icon: <WiRain size={bigIconSize} color={iconColor} /> },
    "Légère pluie dans la matinée": { label: "Légère pluie dans la matinée", icon: <WiDayRain size={bigIconSize} color={iconColor} /> },
    "Légère pluie au cours de l'après-midi": { label: "Légère pluie au cours de l'après-midi", icon: <WiDayRain size={bigIconSize} color={iconColor} /> },
    "Mélange de pluie et de neige": { label: "Mélange de pluie et de neige", icon: <WiRainMix size={bigIconSize} color={iconColor} /> },
    "Mélange de pluie et de neige dans la matinée": { label: "Mélange de pluie et de neige dans la matinée", icon: <WiRainMix size={bigIconSize} color={iconColor} /> },
    "Mélange de pluie et de neige au cours de l'après-midi": { label: "Mélange de pluie et de neige au cours de l'après-midi", icon: <WiRainMix size={bigIconSize} color={iconColor} /> },
    "Pluie, forte par moment": { label: "Pluie, forte par moment", icon: <WiRain size={bigIconSize} color={iconColor} /> },
    "Pluie et chutes de neige": { label: "Pluie et chutes de neige", icon: <WiRainMix size={bigIconSize} color={iconColor} /> },
    "Pluie au cours de l'après-midi": { label: "Pluie au cours de l'après midi", icon: <WiRain size={bigIconSize} color={iconColor} /> },
    "Pluie et chutes de neige dans la matinée": { label: "Pluie et chutes de neige dans la matinée", icon: <WiRainMix size={bigIconSize} color={iconColor} /> },
    "Pluie et chutes de neige au cours de l'après-midi": { label: "Pluie et chutes de neige au cours de l'après-midi", icon: <WiRainMix size={bigIconSize} color={iconColor} /> },
    "Chutes de neige": { label: "Neige", icon: <WiDaySnow size={bigIconSize} color={iconColor} /> },
    "Chutes de neige dans la matinée": { label: "Chutes de neige dans la matinée", icon: <WiDaySnow size={bigIconSize} color={iconColor} /> },
    "Chutes de neige au cours de l'après-midi": { label: "Chutes de neige au cours de l'après-midi", icon: <WiDaySnow size={bigIconSize} color={iconColor} /> },
    "Nuages matinaux suivis d'une après-midi ensoleillée": { label: "Nuages matinaux suivis d'une après-midi ensoleillée", icon: <WiDayCloudy size={bigIconSize} color={iconColor} /> },
    "Orages": { label: "Orages", icon: <WiThunderstorm size={bigIconSize} color={iconColor} /> },
    "Orages épars": { label: "Orages épars", icon: <WiDayThunderstorm size={bigIconSize} color={iconColor} /> },
    "Orages se formant au cours de l'après-midi": { label: "Orages se formant au cours de l'après-midi", icon: <WiDayThunderstorm size={bigIconSize} color={iconColor} /> }
  };

  /* narrative ex:
  Ciel plutôt dégagé. Maximales : 13 à 15 °C. Minimales : 4 à 6 °C.
  Averses. Maximales : 13 à 15 °C. Minimales : 8 à 10 °C.
  Légère pluie dans la matinée. Maximales : 13 à 15 °C. Minimales : 5 à 7 °C.
  Légère pluie au cours de l'après-midi. Maximales : 12 à 14 °C. Minimales : 0 à 2 °C.
  */
  const narratives = data.narrative.split(".");
  const narrative = narratives[0];
  var weatherNarrative = {};
  try {
    weatherNarrative = weatherNarratives[narrative];
  }
  catch (e) {
    console.log('catch exception: ' + JSON.stringify(e));
  }

  if (!weatherNarrative) {
    weatherNarrative = { label: narrative, icon: <WiAlien size={bigIconSize} color={iconColor} /> };
  }

  const min = narratives.length > 2 ? narratives[2].replace(' Minimales :', '') : narratives[1].replace(' Minimales :', '');
  const max = narratives.length > 2 ? narratives[1].replace(' Maximales :', '') : <br />;

  const smallIconSize = 30;
  const iconStyles = { "verticalAlign": "middle" };

  return (
    <div>
      <h3>{data.dayOfWeek} {timeFormatDate(new Date(data.validTimeLocal))}</h3>
      <div>{weatherNarrative.icon}</div>
      <p>Min: {min}</p>
      <p>Max: {max}</p>
      <div><WiSunrise size={smallIconSize} color={iconColor} style={iconStyles} /> {timeFormatTime(new Date(data.sunriseTimeLocal))}</div>
      <div><WiSunset size={smallIconSize} color={iconColor} style={iconStyles} /> {timeFormatTime(new Date(data.sunsetTimeLocal))}</div>
      <div><WiMoonrise size={smallIconSize} color={iconColor} style={iconStyles} /> {timeFormatTime(new Date(data.moonriseTimeLocal))}</div>
      <div><WiMoonset size={smallIconSize} color={iconColor} style={iconStyles} /> {timeFormatTime(new Date(data.moonsetTimeLocal))}</div>
      <div>{moonPhase.icon}</div>
      <p>{moonPhase.label}</p>
      <p>{weatherNarrative.label}</p>
    </div>
  );
};
